<div class="footer-content">
    <section class="container">
        <div class="col about-col">
            <div class="title" trans>About</div>
            <div class="content">{{siteDescription()}}</div>
        </div>

        <custom-menu class="col" position="footer-1" [showTitle]="true"></custom-menu>
        <custom-menu class="col" position="footer-2" [showTitle]="true"></custom-menu>
        <custom-menu class="col" position="footer-3" [showTitle]="true"></custom-menu>
    </section>
</div>

<div class="footer-bar">
    
            <div class="right links">
                <span trans>Copyright</span> &copy; {{year()}} {{siteName()}}
            <div class="social-icons">
             
                <a mat-icon-button href="https://www.facebook.com/My-Cine-Hub-112805733616541" class="social-icon socialblock" target="_blank">
               <img src="https://img.icons8.com/material/48/000000/facebook-f.png"/>
                </a>
                 <a mat-icon-button href="https://twitter.com/MyCineHub1" class="social-icon socialblock" target="_blank">
               <img src="https://img.icons8.com/material/48/000000/twitter-squared.png"/>
                </a>
                  <a mat-icon-button href="https://in.pinterest.com/mycinehub/" class="social-icon socialblock" target="_blank">
               <img src="https://img.icons8.com/material/48/000000/pinterest--v1.png"/>
                </a>
                <a mat-icon-button href="https://www.instagram.com/mycinehub/" class="social-icon socialblock" target="_blank">
               <img src="https://img.icons8.com/material/48/000000/instagram-new--v1.png"/>
                </a>
                   <a mat-icon-button href="https://www.reddit.com/r/mycinehub/" class="social-icon socialblock" target="_blank">
              <img src="https://img.icons8.com/material/48/000000/reddit.png"/>
                </a>
                
            </div>
        </div>
</div>
