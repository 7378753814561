import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BlogArticle} from '../../../models/blog-article';
import {BlogService} from '../blog.service';
import {finalize} from 'rxjs/operators';
import {TitleUrlsService} from '../../titles/title-urls.service';
import {InfiniteScroll} from '@common/core/ui/infinite-scroll/infinite.scroll';
import {PaginationResponse} from '@common/core/types/pagination/pagination-response';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'blog-category',
    templateUrl: './blog-category.component.html',
    styleUrls: ['./blog-category.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogCategoryComponent extends InfiniteScroll implements OnInit {
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public articles$: BehaviorSubject<PaginationResponse<BlogArticle>> = new BehaviorSubject(null);

    constructor(
        private blog: BlogService,
        protected zone: NgZone,
        public urls: TitleUrlsService,
        public route: ActivatedRoute,
        public router: Router,

    ) {

        super();
       //let blogcat =route.snapshot.url[1].path; // array of states
    }

    ngOnInit() {
        this.loadArticles();
        super.ngOnInit();

    }

    private loadArticles() {
        this.loading$.next(true);
        const page = this.articles$.value ? (this.articles$.value.current_page + 1) : 1;
        const cat=this.route.snapshot.url[1].path;
        this.blog.getAllCat({perPage: 10, page, stripHtml: true, category: cat})
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                if (this.articles$.value) {
                    response.pagination.data = [...this.articles$.value.data, ...response.pagination.data];
                }
                this.articles$.next(response.pagination);
            });
    }

    protected loadMoreItems() {
        this.loadArticles();
    }

    protected canLoadMore() {
        return this.articles$.value && (this.articles$.value.current_page < this.articles$.value.last_page);
    }

    protected isLoading() {
        return this.loading$.value;
    }
}
