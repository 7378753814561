<form (ngSubmit)="openSearchPage()" autocomplete="off" class="srchsize">
    <label for="autocomplete-input" *ngIf="label" trans>{{label}}</label>
    <div class="input-wrapper">
        <input class="srchsize" 
            id="autocomplete-input"
            [formControl]="searchControl"
            [matAutocomplete]="auto"
            [placeholder]="placeholder"
            trans-placeholder
            #inputEl
            autocomplete="off"
            materialNavbarMobileSearch
        >
        <button type="submit" mat-icon-button class="search-button">
            <mat-icon svgIcon="search"></mat-icon>
        </button>

           <a mat-icon-button href=" https://www.mycinehub.com/search?query=bollywood
" style="position: absolute; padding-top: 5px; padding-left: 5px;" class="social-icon socialblock" data-toggle="tooltip" title="Bollywood">
               <img src="client/assets/images/bollywood.png"/>
                </a>
              
                   <a mat-icon-button href="https://www.mycinehub.com/search?query=romantic" style="position: absolute; margin-left: 40px; padding-top: 5px; padding-left: 5px;" class="social-icon socialblock" data-toggle="tooltip" title="Romantic">
               <img src="client/assets/images/love.png"/>
                </a> 
                   <a mat-icon-button href="https://www.mycinehub.com/search?query=action" style="position: absolute; margin-left: 80px; padding-top: 5px; padding-left: 5px;" class="social-icon socialblock" data-toggle="tooltip" title="Action">
               <img src="client/assets/images/action.png"/>
                </a>
    </div>
</form>

<mat-autocomplete #auto="matAutocomplete" class="main-search-autocomplete-panel" (optionSelected)="selectResult($event)" [displayWith]="displayFn">
    <mat-option *ngFor="let result of results$ | async" [value]="result">
        <media-image [src]="result.poster" [alt]="result.name" size="small"></media-image>
        <div class="media-body">
            <div class="name">
                <span>{{result.name}}</span>
                <span *ngIf="!isPerson(result)">&nbsp;({{result.year}})</span>
            </div>
            <known-for-widget [person]="result"></known-for-widget>
            <div class="description">{{result.description}}</div>
        </div>
    </mat-option>
</mat-autocomplete>
     