import {Injectable} from '@angular/core';
import {BlogArticle} from '../../models/blog-article';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {BackendResponse} from '@common/core/types/backend-response';

@Injectable({
    providedIn: 'root'
})
export class BlogService {
    constructor(private http: AppHttpClient) {}

    public getAll(params: {perPage?: number, page?: number, stripHtml?: boolean}): PaginatedBackendResponse<BlogArticle> {
        return this.http.get('blog', params);
    }

      public getAllCat(params: {perPage?: number, page?: number, stripHtml?: boolean, category?: any}): PaginatedBackendResponse<BlogArticle> {
        return this.http.get('blog', params);
    }

    public get(id: number): BackendResponse<{article: BlogArticle}> {
        return this.http.get('blog/' + id);
    }

    public create(payload: Partial<BlogArticle>): BackendResponse<{article: BlogArticle}> {
        return this.http.post('blog', payload);
    }

    public update(id: number, payload: Partial<BlogArticle>): BackendResponse<{article: BlogArticle}> {
        return this.http.put('blog/' + id, payload);
    }

    public delete(params: {ids: number[]}): BackendResponse<void> {
        return this.http.delete('blog', params);
    }
}
