import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class BlogService {
    constructor(http) {
        this.http = http;
    }
    getAll(params) {
        return this.http.get('blog', params);
    }
    getAllCat(params) {
        return this.http.get('blog', params);
    }
    get(id) {
        return this.http.get('blog/' + id);
    }
    create(payload) {
        return this.http.post('blog', payload);
    }
    update(id, payload) {
        return this.http.put('blog/' + id, payload);
    }
    delete(params) {
        return this.http.delete('blog', params);
    }
}
BlogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlogService_Factory() { return new BlogService(i0.ɵɵinject(i1.AppHttpClient)); }, token: BlogService, providedIn: "root" });
