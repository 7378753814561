import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BlogService} from '../blog.service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {BlogArticle} from '../../../models/blog-article';
import {TitleUrlsService} from '../../titles/title-urls.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'blog-article',
    templateUrl: './blog-article.component.html',
    styleUrls: ['./blog-article.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleComponent implements OnInit {
    public article$: Subject<BlogArticle> = new Subject();
    public loading$: Subject<boolean> = new Subject();
    public sidebarArticles$: Subject<BlogArticle[]> = new Subject();

    constructor(
        private blog: BlogService,
         public urls: TitleUrlsService,
        private route: ActivatedRoute,

    ) {}

    ngOnInit() {
        this.bindToRouteParams();
    }

    public trustHtml(html: string) {
        return html;
    }
    
    private getSidebarArticles() {
        return this.blog.getAll({perPage: 10}).subscribe(response => {
            this.sidebarArticles$.next(response.pagination.data);
        });
    }
    
    private bindToRouteParams() {
        this.route.params.subscribe(params => {
            this.loading$.next(true);
            this.blog.get(+params.id)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                    this.article$.next(response.article);
                });
            this.getSidebarArticles();
        });
    }
}
